import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { URL } from "../Redux/common/url";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function WithdrawalReq() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const battel_List_users = useSelector((state) =>
    state?.allapi?.battel_List_users ? state?.allapi?.battel_List_users : {}
  );
  const get_transaction = useSelector((state) =>
    state?.allapi?.get_withdrwal_list ? state?.allapi?.get_withdrwal_list : []
  );

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );


  const [startBtmodal, setstartBtmodal] = useState(false);
  const [imggg, setimggg] = useState("");
  const handleClose = () => setstartBtmodal(false);
  const handleShow = (e) =>{ 
    setstartBtmodal(true);
    setimggg(e)
  }
  useEffect(() => {
    // battel_List
    // dispatch(allapiAction.getbattellistUser());
    dispatch(allapiAction.getwithdrawallist());
    return () => {};
  }, []);

  return (
    <div>
      <div id="root">
        <SiderBar />

        <Modal show={startBtmodal} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Room Code</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
         <img src={imggg} alt=""  style={{width:"100%"}} />
           
          </Modal.Body>
        
        </Modal>

        <div className="leftContainer">
          <Header />

          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div>
              <Link
                className="btn btn-success"
                to={"/withdrwal"}
                style={{ width: "95%", margin: "10px" }}
              >
                Withdrawal Now
              </Link>
            </div>

            {get_transaction &&
              get_transaction?.map((data, i) => {
                const userbet =
                  profile?.users?._id == data?.owner?._id ? "1" : "2";
                const userwin =
                  userbet == "1" ? data?.ownerresult : data?.playeroneresult;
                const userwinagin =
                  userbet == "1" ? data?.playerone?.uid : data?.owner?.uid;

                return (
                  <div className="main-area">
                    <div className="w-100 py-3 d-flex align-items-center list-item">
                      <div className="center-xy list-date mx-2">
                        <div>
                          {moment(data?.createdAt)?.format("DD MMM")}{" "}
                          {/* 03 Feb */}
                        </div>
                        <small>
                          {" "}
                          {moment(data?.createdAt)?.format("hh:mm A")}{" "}
                          {/* 01:10 PM */}
                        </small>
                      </div>
                      <div className="list-divider-y"></div>
                      <div className="mx-3 d-flex list-body">
                        <div className="d-flex align-items-center">
                          <picture className="mr-2">
                            {/* <img
                              height="32px"
                              width="32px"
                              src="../assest/image/games/kb_ludo_classic.jpeg"
                              alt=""
                              style={{ borderRadius: "5px" }}
                            /> */}
                          </picture>
                        </div>
                        <div className="d-flex flex-column font-8">
                          <div>
                            status.{" "}
                            <b
                              className={
                                data?.status == "complete"
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {data?.status}
                            </b>
                            .
                          </div>
                          <div className="games-section-headline">
                            {data?.reason}
                          </div>
                          <div className="games-section-headline">
                            {data?.utr}
                          </div>
                        </div>

                       {data?.image && <div style={{width:"20px"}} onClick={()=>{handleShow(URL?.API_BASE_URL +data?.image )}}>
                          <img src={URL?.API_BASE_URL +data?.image } style={{width:"100%"}} alt="" />
                        </div>}
                      </div>
                      <div className="right-0 d-flex align-items-end pe-3 flex-column ">
                        <div className="d-flex float-right font-8">
                          <div
                            className={
                              data?.status == "complete"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            ( {data?.type == "cr" ? "+" : "-"} )
                          </div>
                          <picture className="ms-1 mb-1">
                            {data?.status == "complete" ? (
                              <img
                                height="21px"
                                width="21px"
                                src="../assest/image/global-rupeeIcon.png"
                                alt=""
                              />
                            ) : (
                              <img
                                height="21px"
                                width="21px"
                                src="../assest/image/rupeeicon.png"
                                alt=""
                              />
                            )}
                          </picture>
                          <span className="pl-1"> {data?.payableAmount} </span>
                        </div>

                        <div
                          //   className="games-section-headline"
                          className={
                            data?.status == "complete"
                              ? "text-success games-section-headline"
                              : "text-danger games-section-headline"
                          }
                          style={{ fontSize: "0.6em" }}
                        >
                          request Payment : {data?.requestPayment}{" "}
                        </div>

                        <div
                          //   className="games-section-headline "
                          className={
                            data?.status == "complete"
                              ? "text-success games-section-headline"
                              : "text-danger games-section-headline"
                          }
                          style={{ fontSize: "0.6em" }}
                        >
                          Closing Balance: {data?.current_balance}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default WithdrawalReq;
