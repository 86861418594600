import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
function ViewBeetel() {
  const data = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // battel_Details
  const [cencilres, setcencilres] = useState("");
  const [result, setresult] = useState("");
  const [file, setFile] = useState();

  const [startBtmodal, setstartBtmodal] = useState(false);

  const handleClose = () => setstartBtmodal(false);

  const handleShow = () => setstartBtmodal(true);

  const battel_Details = useSelector((state) =>
    state?.allapi?.battel_Details ? state?.allapi?.battel_Details : {}
  );

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    // getbattelDetails

    const intervalId = setInterval(() => {
      dispatch(allapiAction.getbattelDetails({ _id: data?.id }));
      // dispatch(allapiAction.getbattellist("classicmanual"));
    }, 5000);
    dispatch(allapiAction.getbattelDetails({ _id: data?.id }));

    return () => {
      clearInterval(intervalId);
    };
  }, [data]);


  const [submitbtn, setsubmitbtn] = useState(true);
  const hendleWinSubmit = () => {
    // submitWinner
    setsubmitbtn(false);
    if (!file) {
      toast.error("please select image ");
      setsubmitbtn(true);
    } else {
      const data1 = new FormData();

      const bettelId = data?.id;

      data1.append("_id", bettelId);
      data1.append("status", result);
      data1.append("image", file);

      dispatch(allapiAction.submitWinner(data1, navigate));
      return () => {};
    }
  };
  const hendleWinSubmit2 = (e) => {
    // submitWinner
    e.preventDefault();
    setsubmitbtn(false);
    // if (!file) {
    //   toast.error("please select image ");
    // } else {
    const data1 = new FormData();

    const bettelId = data?.id;

    data1.append("_id", bettelId);
    data1.append("status", result);
    // data1.append("image", file);

    dispatch(allapiAction.submitWinner(data1, navigate));
    return () => {};
    // }
  };
  const hendleWinSubmit3 = (e) => {
    // submitWinner
    e.preventDefault();
    setsubmitbtn(false);
    // if (!file) {
    //   toast.error("please select image ");
    // } else {
    const data1 = new FormData();

    const bettelId = data?.id;

    data1.append("_id", bettelId);
    data1.append("status", result);
    data1.append("reason", cencilres);
    // data1.append("image", file);

    dispatch(allapiAction.submitWinner(data1, navigate));
    return () => {};
    // }
  };
  const hendlecencelbefror = (e) => {
    // submitWinner
    // e.preventDefault();
    setsubmitbtn(false);
    console.log(battel_Details);

    dispatch(allapiAction.cencelBettelAfterJoin({ _id: e }, navigate));
    return () => {};
    // }
  };

  function copy() {
    const el = document.createElement("input");
    el.value = battel_Details?.roomcode;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Copied!");
  }
  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );

  const btnHide =
    profile?.users?._id == battel_Details?.owner?._id
      ? battel_Details?.ownerssresulstatus
      : battel_Details?.playeronessresulstatus;
  const resultType =
    profile?.users?._id == battel_Details?.owner?._id
      ? battel_Details?.ownerresult
      : battel_Details?.playeroneresult;
  console.log(btnHide);

  console.log(resultType);
  const hendletosubmitcencel = () => {
    setstartBtmodal(false);
  };
  return (
    <div>
      {" "}
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <Modal show={startBtmodal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label>Reason</label>
              <input
                name="reason"
                className="form-control"
                placeholder="enter Reason"
                type={"text"}
                onChange={(e) => {
                  setcencilres(e?.target.value);
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={hendletosubmitcencel}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="battleCard-bg">
              <div className="battleCard">
                <div className="players cxy pt-2">
                  <div className="flex-column cxy">
                    <h5></h5>
                    <img
                      src="../assest/image/Avatar2.png"
                      width="50px"
                      height="50px"
                      alt=""
                      style={{ borderRadius: "50%" }}
                    />
                    <div style={{ lineHeight: 1 }}>
                      <span className="Home_betCard_playerName__57U-C">
                        {battel_Details?.owner?.uid}
                      </span>
                    </div>
                  </div>
                  <img
                    className="mx-3"
                    src="../assest/image/versus.png"
                    width="23px"
                    alt=""
                  />
                  <div className="flex-column cxy">
                    <h5></h5>
                    <img
                      src="../assest/image/Avatar2.png"
                      width="50px"
                      height="50px"
                      alt=""
                      style={{ borderRadius: "50%" }}
                    />
                    <div style={{ lineHeight: 1 }}>
                      <span className="Home_betCard_playerName__57U-C">
                        {battel_Details?.playerone?.uid}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="amount cxy mt-2">
                  <span style={{ opacity: "0.8" }}>Playing for</span>
                  <img
                    className="mx-1"
                    src="../assest/image/global-rupeeIcon.png"
                    width="25x"
                    alt=""
                  />
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "700",
                      opacity: "0.8",
                    }}
                  >
                    {battel_Details?.amount}
                  </span>
                </div>
                <div>
                  <p>{battel_Details?._id}</p>
                </div>
                <div className="thin-divider-x my-3"></div>

                {battel_Details?.status == "opne" &&
                battel_Details?.start1 == "false" ? (
                  <div className="roomCode cxy flex-column">
                    <div className="text-center">
                      {/* <div>
                    <p className="gametitle">Game Only Ludo King Classic Ka hi manya hoga</p>
                    </div> */}
                      <div>
                        <p className=""> रूम कोड का इंतजार है</p>
                      </div>
                      <img
                        className="border-50"
                        src="../assest/image/loader.png"
                        width="50px"
                        height="50px"
                        alt=""
                      />
                      {/* <div>Room Code</div>
                      <span>
                        <input type="hidden" id="roomCode" value="07769347" />
                        {battel_Details?.roomcode}
                      </span> */}
                    </div>
                    {/* <button
                      className="bg-green playButton position-static mt-2"
                      onClick={() => {
                        copy();
                      }}
                      fdprocessedid="iamgla"
                    >
                      Copy Code
                    </button> */}
                  </div>
                ) : (
                  <div className="roomCode cxy flex-column">
                    <div className="text-center">
                      {/* <div>
                    <p className="gametitle">Game Only Ludo King Classic Ka hi manya hoga</p>
                    </div> */}
                      <div>
                        {/* <p className="gametitle2">
                          {" "}
                          गेम सिर्फ लडूो कि ंग क्लासिक का ही मान्य होगा।
                        </p> */}
                      </div>
                      <div>Room Code</div>
                      <span>
                        <input type="hidden" id="roomCode" value="07769347" />
                        {battel_Details?.roomcode}
                      </span>
                    </div>
                    <button
                      className="bg-green playButton position-static mt-2"
                      onClick={() => {
                        copy();
                      }}
                      fdprocessedid="iamgla"
                    >
                      Copy Code
                    </button>
                  </div>
                )}
                <div className="cxy app-discription flex-column">
                  <span style={{ opacity: "0.8" }}>
                    {" "}
                    {/* Play ludo game in khel mantri App */}
                    {t("ludoapp")}
                  </span>
                  <div className="mt-2">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.ludo.king"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="mr-2"
                        src="../assest/image/android.png"
                        width="128px"
                        height="38px"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://itunes.apple.com/app/id993090598"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="../assest/image/ios.png"
                        width="128px"
                        height="38px"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="thin-divider-x my-3"></div>
                <div className="rules">
                  <span className="cxy mb-1">
                    <u> {t("rules")}</u>
                  </span>
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item">
                      {t("rule1")}
                      {/* Record every game while playing. */}
                    </li>
                    <li className="list-group-item">
                      {t("rule2")}
                      {/* For cancellation of game, video proof is necessary. */}
                    </li>
                    <li className="list-group-item">
                      {t("rule3")}
                      {/* 50 Penality will be charged for updating wrong result. */}
                    </li>
                    <li className="list-group-item">
                      {t("rule4")}
                      {/* 25 Penality will be charged for not updating result. */}
                    </li>
                  </ol>
                </div>
                {battel_Details?.status == "opne" &&
                battel_Details?.start1 == "false" ? (
                  <div>
                    <div className="match-status-border row">
                      <div
                        className="col-12"
                        style={{ fontSize: "20px", fontWeight: "800" }}
                      >
                        Match Status :- {battel_Details?.status}
                      </div>

                      <div align="center">
                        {submitbtn && (
                          <div align="center">
                            <input
                              type="submit"
                              className="btn btn-success"
                              value="cancel"
                              onClick={(e) => {
                                hendlecencelbefror(battel_Details?._id);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div></div>
                  </div>
                ) : (
                  <div>
                    <div className="match-status-border row">
                      <div
                        className="col-6"
                        style={{ fontSize: "20px", fontWeight: "800" }}
                      >
                        Match Status
                      </div>
                    </div>
                    {btnHide !== "true" && (
                      <label className="radio-inline">
                        <input
                          type="radio"
                          name="battleResult"
                          id="won_div"
                          value="win"
                          className="rdbtn1"
                          required=""
                          onChange={() => {
                            setresult("win");
                          }}
                        />
                        I Won
                      </label>
                    )}
                    {btnHide !== "true" && (
                      <label className="radio-inline">
                        <input
                          type="radio"
                          name="battleResult"
                          id="lost_div"
                          value="lost"
                          className="rdbtn1"
                          required=""
                          onChange={() => {
                            setresult("loss");
                          }}
                        />
                        I Lost
                      </label>
                    )}
                    {btnHide !== "true" && (
                      <label className="radio-inline">
                        <input
                          type="radio"
                          name="battleResult"
                          id="cancel_div"
                          value="cancel"
                          className="rdbtn1"
                          required=""
                          onChange={() => {
                            setresult("cancel");
                          }}
                        />
                        Cancel
                      </label>
                    )}

                    {btnHide == "false" && (
                      <div
                        id="win"
                        style={
                          result == "win"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        {/* <form
                    action="#
                    enctype="multipart/form-data"
                    method="post"
                    style={{
                      border: "1px solid grey",
                      backgroundColor: "#efefef",
                      padding: "10px",
                    }}
                    align="center"
                  > */}
                        {/* <input
                      type="hidden"
                      name="_token"
                      value="pwtj6jSvstvLfqPffS28RPyLpXHzgg4znuE6vxMQ"
                    />
                    <input type="hidden" name="player_id" value="386" />
                    <input type="hidden" name="battleResult" value="win" /> */}

                        <div id="screen_shot_upload" align="center">
                          <label>
                            <input
                              type="file"
                              name="screenshot"
                              className="form-control"
                              required=""
                              accept="image/*"
                              onChange={(e) => {
                                handleFileChange(e);
                              }}
                            />
                          </label>
                        </div>
                        <div align="center">
                        {submitbtn && (
                            <input
                              type="submit"
                              className="btn btn-success"
                              value="Submit Result"
                              onClick={() => {
                                hendleWinSubmit();
                              }}
                            />
                          )}
                        </div>
                        <br />
                        {/* </form> */}
                      </div>
                    )}
                    {/* <!--loser form--> */}
                    {btnHide == "false" && (
                      <div
                        id="lose"
                        style={
                          result == "loss"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <form
                          // action="#"
                          // enctype="multipart/form-data"
                          // method="post"
                          // style="
                          //   border: 1px solid grey;
                          //   background-color: #efefef;
                          //   padding: 10px;
                          // "

                          style={{
                            border: "1px solid grey",
                            backgroundColor: "#efefef",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          <div id="lost_message" align="center">
                            <label> Best Luck for next Time, Try Again! </label>
                          </div>

                          <div align="center">
                          {submitbtn && (
                              <button
                                type="submit"
                                className="btn btn-success"
                                // value="Submit Result"
                                // hendleWinSubmit2
                                onClick={(e) => {
                                  hendleWinSubmit2(e);
                                }}
                              >
                                Submit Result
                              </button>
                            )}
                          </div>
                          <br />
                        </form>
                      </div>
                    )}
                    {/* <!--cancel form--> */}
                    {btnHide == "false" && (
                      <div
                        id="cancel"
                        style={
                          result == "cancel"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <form
                          // action="#"
                          // enctype="multipart/form-data"
                          // method="post"
                          // style="
                          //   border: 1px solid grey;
                          //   background-color: #efefef;
                          //   padding: 10px;
                          // "
                          style={{
                            border: "1px solid grey",
                            backgroundColor: "#efefef",
                            padding: "10px",
                          }}
                          align="center"
                        >
                          <input
                            type="hidden"
                            name="_token"
                            value="pwtj6jSvstvLfqPffS28RPyLpXHzgg4znuE6vxMQ"
                          />
                          <input type="hidden" name="player_id" value="386" />
                          <input
                            type="hidden"
                            name="battleResult"
                            value="cancel"
                          />

                          <div id="cancel_reason" align="center">
                            <label>
                              <div className="row">
                                <div className="col-4 ">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setcencilres("No Room Code");
                                    }}
                                    class="btn btn-secondary"
                                  >
                                    No Room Code
                                  </button>
                                  {/* <p>No Room Code</p> */}
                                </div>
                                <div className="col-4 ">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setcencilres("Not Joined");
                                    }}
                                    class="btn btn-secondary"
                                  >
                                    Not Joined
                                  </button>
                                  {/* <p>Not Joined</p> */}
                                </div>
                                <div className="col-4 ">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setcencilres("Not Playing");
                                    }}
                                    class="btn btn-secondary"
                                  >
                                    Not Playing
                                  </button>
                                  {/* <p>Not Playing</p> */}
                                </div>
                                <div className="col-4 mt-2">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setcencilres("Dont Want To Play");
                                    }}
                                    class="btn btn-secondary"
                                  >
                                    Dont Want To Play
                                  </button>
                                  {/* <p>Dont Want To Play</p> */}
                                </div>
                                <div className="col-4  mt-2">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setcencilres("Game Not Start");
                                    }}
                                    class="btn btn-secondary"
                                  >
                                    Game Not Start
                                  </button>
                                  {/* <p>Game Not Start</p> */}
                                </div>
                                <div className="col-4  mt-2">
                                  <button
                                    type="button"
                                    onClick={handleShow}
                                    class="btn btn-secondary"
                                  >
                                    Other
                                  </button>
                                  {/* <p>Other</p> */}
                                </div>
                              </div>
                              {/* <textarea
                            name=""
                            className="form-control"
                            required=""
                          ></textarea> */}
                            </label>
                          </div>

                          <div align="center">
                          {submitbtn && (
                              <input
                                type="submit"
                                onClick={(e) => {
                                  hendleWinSubmit3(e);
                                }}
                                className="btn btn-success"
                                value="Submit Result"
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    )}

                    {btnHide == "true" &&
                      resultType == "loss" &&
                      battel_Details?.adminForword == "false" && (
                        <div className="resloss">
                          <p className="gametitle2">
                            {" "}
                            Match Status Losses - Request Already Submitted{" "}
                          </p>
                        </div>
                      )}
                    {btnHide == "true" &&
                      resultType == "win" &&
                      battel_Details?.adminForword == "false" && (
                        <div className="resloss">
                          <p className="gametitle3">
                            {" "}
                            Match Status WIN - Request Already Submitted{" "}
                          </p>
                        </div>
                      )}
                    {btnHide == "true" &&
                      resultType == "cancel" &&
                      battel_Details?.adminForword == "false" && (
                        <div className="resloss">
                          <p className="gametitle4">
                            {" "}
                            Match Status cancel - Request Already Submitted{" "}
                          </p>
                        </div>
                      )}
                    {battel_Details?.adminForword == "true" && (
                      <div className="resloss">
                        <p className="gametitle5">
                          Please Wait For Result - Admin Will Declare Result.
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default ViewBeetel;
