import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
function Withdrwal() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );
  const account_details = useSelector((state) =>
    state?.allapi?.account_details ? state?.allapi?.account_details : {}
  );
  const get_setting = useSelector((state) =>
    state?.allapi?.get_setting ? state?.allapi?.get_setting : {}
  );

  //   useEffect(() => {
  //     setaccountdetailsupdate(account_details);
  //   }, [account_details]);

  useEffect(() => {
    // getaccuontdetails

    dispatch(allapiAction.getaccuontdetails());
    dispatch(allapiAction.getsetting());
    return () => { };
    // setaccountdetailsupdate(profile?.users?.accountId);
  }, []);

  const [amount, setamount] = useState(0);
  const [dataold, setdataold] = useState({});
  const [paymentMode, setpaymentMode] = useState("upi");
  const [startBtmodal, setstartBtmodal] = useState(false);

  const handleClose = () => setstartBtmodal(false);
  const handleShow = () => setstartBtmodal(true);
  console.log(profile);
  console.log(profile?.users?.accountId);
  console.log(dataold);

  const hendletochange = (e) => {
    const { name, value } = e?.target
    setdataold({ ...dataold, [name]: value })
  }

  const paymenton =
    startBtmodal == "upi"
      ? profile?.users?.accountId?.upiId
      : profile?.users?.accountId?.paytmWallet;

  const hendletoView = () => {
    if (profile?.users?.keyverify == "pending") {
      toast.error("Please wait for kyc verify");
    } else if (profile?.wallets?.winningBal < amount) {
      toast.error("your balance Insufficient");
    } else if (get_setting?.minimumwithdrwal > amount) {
      toast.error("minimum withdrawal " + get_setting?.minimumwithdrwal);
    } else if (get_setting?.maxmumwithdrwal < amount) {
      toast.error("maxmum withdrawal " + get_setting?.maxmumwithdrwal);
    } else if (!paymentMode) {
      toast.error("Please select Payment mode");
    } else {

      if (paymentMode == "upi") {
        if (!dataold?.upi) {
          toast.error("Please enter upi");
        } else if(!dataold?.accountHolder) {
          toast.error("Please enter account holder name");
        } else {
          setstartBtmodal(true);
        }
        
      } else {
        if (!dataold?.accountNumber) {
          toast.error("Please enter account number");
        } else if(!dataold?.ifsc) {
          toast.error("Please enter ifsc code");
        }else if(!dataold?.accountHolder) {
          toast.error("Please enter account holder name");
        } else {
          setstartBtmodal(true);
        }
      }

      // withdrawaluserAmount
      // const result = await dispatch(
      //   allapiAction.withdrawaluserAmount({ amount: amount }, navigate)
      // );
      // return () => {};
    }
  };
  const [first, setfirst] = useState(true);
  const submitbotton = async () => {
    // profile?.wallets?.closeBal
    setfirst(false);
    if (profile?.users?.keyverify == "pending") {
      toast.error("Please wait for kyc verify");
      setfirst(true);
    } else if (profile?.users?.keyverify == "reject") {
      toast.error("your kyc reject by admin please complete your details");
      setfirst(true);
    } else if (profile?.wallets?.winningBal < amount) {
      toast.error("your balance Insufficient");
      setfirst(true);
    } else if (get_setting?.minimumwithdrwal > amount) {
      toast.error("minimum withdrawal " + get_setting?.minimumwithdrwal);
      setfirst(true);
    } else if (get_setting?.maxmumwithdrwal < amount) {
      toast.error("maxmum withdrawal " + get_setting?.maxmumwithdrwal);
      setfirst(true);
    } else {
      // withdrawaluserAmount



      const result = await dispatch(
        allapiAction.withdrawaluserAmount(
          {
            amount: amount,
            paymentMode: paymentMode,
            upi: paymentMode == "upi" ? dataold?.upi : "--",
            ifsc: paymentMode !== "upi" ? dataold?.ifsc : "--",
            accountNumber: paymentMode !== "upi" ? dataold?.accountNumber : "--",
            accountHolder: dataold?.accountHolder
          },
          navigate
        )
      );
      setfirst(true);
      return () => { };
    }
  };
  //   keyverify

  return (
    <div>
      <div id="root">
        <SiderBar />

        <Modal show={startBtmodal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <label>Room code</label>
            <input
              name="roomcode"
              className="form-control"
              placeholder="enter room code"
              type={"number"}
              onChange={(e) => {
                hendletochange(e);
              }}
            /> */}
            <p>
              Are you sure you want to withdraw the Amount {amount} in your “
              {paymentMode}” no {paymenton}
            </p>
          </Modal.Body>
          <Modal.Footer>
            {first && (
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            )}
            {first && (
              <Button variant="primary" onClick={submitbotton}>
                Submit
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <div className="leftContainer">
          <Header />
          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="withdrawalBox">
              <div className="wfb">
                <div className="row">
                  <div className="col-6"></div>
                  <div className="col-6"></div>
                </div>
              </div>
            </div>

            <div class="d-flex position-relative align-items-center">
              <div className="px-4 col-12 py-3">
                <div class="games-section-title">
                  {t("withdrawaloption")}
                  {/* Choose withdrawal option */}
                </div>
                {profile?.users?.accountId == undefined && (
                  <Link
                    class="d-flex align-items-center profile-wallet bg-light m-4 py-3"
                    to="/kyc-complete"
                  >
                    <picture class="ms-4">
                      <img width="32px" src="/images/alert.svg" alt="" />
                    </picture>
                    <div class=" mytext text-muted ">
                      <span>Complete KYC to take Withdrawals</span>
                    </div>
                  </Link>
                )}
                {profile?.users?.keyverify == "pending" && (
                  <div
                    class="d-flex align-items-center profile-wallet bg-light m-4 py-3"
                  // to="/kyc-complete"
                  >
                    {/* <picture class="ms-4">
                      <img width="32px" src="/images/alert.svg" alt="" />
                    </picture> */}
                    <div class="  col-12 text-muted ">Wait for verify</div>
                  </div>
                )}
                {/* {profile?.users?.keyverify == "pending" && (
                  <div
                    class="d-flex align-items-center profile-wallet bg-light m-4 py-3"
                    // to="/kyc-complete"
                  >
                    <picture class="ms-4">
                      <img width="32px" src="/images/alert.svg" alt="" />
                    </picture>
                    <div class="  text-muted ">
                      Wait for verify
                    </div>
                  </div>
                )} */}
                {profile?.users?.keyverify == "reject" && (
                  <div
                    class="d-flex align-items-center   m-4 py-3"
                  // to="/kyc-complete"
                  >
                    <div class=" col-12  text-muted ">
                      <p className="text-danger">{account_details?.reason}</p>
                      <Link class="btn btn-primary" to="/kyc-complete">
                        kyc update
                      </Link>
                    </div>
                  </div>
                )}

                <div class="mt-3">
                  {/* <div
                    class="add-fund-box d-flex align-items-center mt-4"
                    style={{paddingTop: "15px", height: "60px"}}
                  >
                    <div class="d-flex align-items-center">
                      <img width="48px" src="/images/upi.webp" alt="" />
                      <div class="d-flex justify-content-center flex-column ms-4">
                        <div class="jss4">Withdraw to UPI</div>
                        <div class="jss5">Minimum withdrawal amount ₹10</div>
                      </div>
                    </div>
                  </div> */}

                  {profile?.users?.accountId &&
                    profile?.users?.keyverify == "complete" && (
                      <div
                        class="d-flex align-items-center   m-4 py-3"
                      // to="/kyc-complete"
                      >
                        <div class=" col-6  text-muted ">
                          <p className="text-muted">
                            Account verified

                          </p>
                          {/* <Link class="btn btn-primary" to="/kyc-complete">
                            KYC Update
                          </Link> */}
                        </div>
                        <div class=" col-6  text-muted ">
                          {/* <p className="text-muted">
                            Account verified
                          
                          </p> */}
                          <Link class="btn btn-primary" to="/kyc-complete">
                            KYC Update
                          </Link>
                        </div>
                      </div>
                    )}
                  {/* {profile?.users?.accountId &&
                    profile?.users?.keyverify == "complete" && (
                      <div
                        class="add-fund-box d-flex align-items-center mt-4"
                        style={{ paddingTop: "15px", height: "60px" }}
                      >
                        <div class="d-flex align-items-center">
                          <img
                            width="48px"
                            src="../assest/image/bank.png"
                            alt=""
                          />

                          <div class="d-flex justify-content-center flex-column ms-4">
                            <div class="jss4">
                            
                            </div>
                            <div class="jss5">
                           
                              {profile?.users?.accountId?.accountNumber}
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}

                  {profile?.users?.accountId &&
                    profile?.users?.keyverify == "complete" && (
                      <div style={{ marginBottom: "150px" }}>
                        <div style={{ marginTop: "50px" }} class="UIDtext">
                          <div class="kyc-doc-input">
                            {/* <input
                              type="number"
                              name="idNumber"
                              autocomplete="off"
                              id="inpf1"
                              minlength="12"
                              required=""
                              fdprocessedid="4zkm5f"
                              //   value={accountdetailsupdate?.idNumber}
                              onChange={(e) => {
                                setamount(e.target.value);
                              }}
                            /> */}

                            <select
                              class="kyc-input-text form-control kyc-input mt-2"
                              style={{ width: "100%", padding: "10px" }}
                              value={paymentMode}
                              onChange={(e) => {
                                setpaymentMode(e?.target?.value);
                              }}
                            >
                              {/* <option value={""}>Select Payment Mode</option> */}
                              <option value={"upi"}>Upi</option>
                              <option value={"account"}>Account</option>
                            </select>
                            <div class="label"> {t("selectpay")}</div>
                          </div>
                        </div>

                        {paymentMode == "upi" && <div style={{ marginTop: "50px" }} class="UIDtext">
                          <div class="kyc-doc-input">
                            <input
                              type="text"
                              name="upi"
                              autocomplete="off"
                              id="inpf1"
                              minlength="12"
                              required=""
                              fdprocessedid="4zkm5f"
                              //   value={accountdetailsupdate?.idNumber}
                              onChange={(e) => {
                                hendletochange(e);
                              }}
                            />
                            <div class="label"> UPI</div>
                          </div>
                        </div>}

                        <div style={{ marginTop: "50px" }} class="UIDtext">
                          <div class="kyc-doc-input">
                            <input
                              type="text"
                              name="accountHolder"
                              autocomplete="off"
                              id="inpf1"
                              minlength="12"
                              required=""
                              fdprocessedid="4zkm5f"
                              //   value={accountdetailsupdate?.idNumber}
                              onChange={(e) => {
                                hendletochange(e);
                              }}
                            />
                            <div class="label">Account Holder</div>
                          </div>
                        </div>

                        {paymentMode !== "upi" && <div style={{ marginTop: "50px" }} class="UIDtext">
                          <div class="kyc-doc-input">
                            <input
                              type="text"
                              name="accountNumber"
                              autocomplete="off"
                              id="inpf1"
                              minlength="12"
                              required=""
                              fdprocessedid="4zkm5f"
                              //   value={accountdetailsupdate?.idNumber}
                              onChange={(e) => {
                                hendletochange(e);
                              }}
                            />
                            <div class="label"> Account Number</div>
                          </div>
                        </div>}

                        {paymentMode !== "upi" && <div style={{ marginTop: "50px" }} class="UIDtext">
                          <div class="kyc-doc-input">
                            <input
                              type="text"
                              name="ifsc"
                              autocomplete="off"
                              id="inpf1"
                              minlength="12"
                              required=""
                              fdprocessedid="4zkm5f"
                              //   value={accountdetailsupdate?.idNumber}
                              onChange={(e) => {
                                hendletochange(e);
                              }}
                            />
                            <div class="label"> Ifsc</div>
                          </div>
                        </div>}
                        <div style={{ marginTop: "50px" }} class="UIDtext">
                          <div class="kyc-doc-input">
                            <input
                              type="text"
                              name="ifsc"
                              autocomplete="off"
                              id="inpf1"
                              minlength="12"
                              required=""
                              fdprocessedid="4zkm5f"
                              //   value={accountdetailsupdate?.idNumber}
                              onChange={(e) => {
                                setamount(e?.target?.value);
                              }}
                            />
                            <div class="label"> Amount</div>
                          </div>
                        </div>


                        <p>
                          {" "}
                          {t("bankinstats")} {t("min")} :{" "}
                          {get_setting?.minimumwithdrwal} , {t("max")} :-{" "}
                          {get_setting?.maxmumwithdrwal}{" "}
                        </p>
                      </div>
                    )}

                  {profile?.users?.accountId &&
                    profile?.users?.keyverify == "complete" && (
                      <div class="refer-footer">
                        <input
                          type="submit"
                          class="disabledButton btn btn-success btn-lg"
                          style={{ width: "100%" }}
                          value="Withdrawal"
                          id="next"
                          onClick={() => {
                            hendletoView();
                          }}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Withdrwal;
