import React from "react";

function RightContainer() {
  return (
    <div>
      {" "}
      <div class="rightContainer">
        <div class="rcBanner flex-center">
          <picture class="rcBanner-img-container">
            <img src="../assest/image/logo.png" alt="" />
          </picture>
          <div class="rcBanner-text">
          Demo Ludo <span class="rcBanner-text-bold">Win Real Cash!</span>
          </div>
          <div class="rcBanner-footer">
            For best experience, open&nbsp;
            <a
              href="#!"
              style={{
                color: "rgb(44, 44, 44)",
                fontWeight: "500",
                textDecoration: "none",
              }}
            >
              demo.com
            </a>
            &nbsp;on&nbsp;
            <img src="../assest/image/global-chrome.png" alt="" />
            &nbsp;chrome mobile
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightContainer;
